<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Edit Asset</span>
        </v-card-title>
        <v-card-text>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HasDialog from '@/mixins/HasDialog'

export default {
  name: 'EditUserDialog',

  mixins: [ HasDialog ],

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data () {
    return {

    }
  }
}
</script>
