<template>
  <div>
    <v-btn
      color="primary"
      depressed
      x-small
      outlined
      :loading="loading"
    >
      Edit
    </v-btn>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red"
          depressed
          x-small
          outlined
          class="ml-2"
          v-bind="attrs"
          v-on="on"
          :loading="deleteLoading"
        >
          Delete
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Delete User {{ user.name }}?
        </v-card-title>
        <v-card-text>Deleting this user will remove them from the account and remove their name from any bookings they have made.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red"
            text
            @click="deleteUser"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <EditUserDialog
      :dialog.sync="editDialog"
      :user="user"
    />

    <SnackBar success ref="successBar">
      Booking created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ error.message }}
    </SnackBar>
  </div>
</template>

<script>
import SnackBar from '@/components/SnackBar'
import EditUserDialog from '@/components/users/EditUserDialog'

export default {
  name: 'UserActionsColumn',

  components: {
    SnackBar,
    EditUserDialog
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      deleteLoading: false,
      loading: false,
      deleteDialog: false,
      error: {},
      editDialog: false
    }
  },

  methods: {
    deleteUser () {
      this.deleteLoading = true
      
      this.$api.delete(`users/${this.user.id}`)
        .then(() => {
          // this.$refs.successBar.open()
          // this.closeDialog()
        })
        .catch(error => {
          this.error = error
          this.$refs.errorBar.open()
        })
        .finally(() => { this.deleteLoading = false })
    }
  }
}
</script>

<style lang="css" scoped>
</style>