<template>
  <div>
    <DataTable
      :cols="cols"
      :path="`accounts/${$auth.account.id}/users`"
      :request-params="{ with: ['roles', 'account'] }"
      default-order-by="created_at"
    >
      <template v-slot:item.roles="{ item: user }">
        <v-chip
          v-for="role in user.roles"
          :key="role.id"
          small
        >
          {{ role.name }}                  
        </v-chip>
      </template>
      <template v-slot:item.account="{ item: user }">
        <v-chip small>
          {{ user.account ? user.account.name : '-' }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item: user }">
        <UserActionsColumn :user="user" />
      </template>
    </DataTable>
  </div>
</template>
<script>
import DataTable from '@/components/DataTable'
import UserActionsColumn from '@/components/users/UserActionsColumn'

export default {
  name: 'Users',

  components: {
    DataTable,
    UserActionsColumn
  },

  data () {
    return {
      cols: [
        { text: 'Name', value: 'name' },
        { text: 'Name', value: 'email' },
        { text: 'Role', value: 'roles' },
        { text: 'Account', value: 'account' },
        { text: 'Actions', value: 'actions' },
      ]
    }
  }
}
</script>
